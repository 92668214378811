import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
// import loading from 'images/icons/three-dots.svg'

class SearchQuery extends Component {
  constructor(props) {
    super(props)

    // this.state = {
    //   query: props.query
    // }
  }

  render() {
    return (
      <div className="row search">

        <form method="GET" action={this.props.url}>
          <div className="col-md-3">
            <input name="q" type="text" defaultValue={this.props.query} placeholder={this.props.placeholder} />
          </div>
          <div className="col-md-3 actions">
            <button className="btn btn-primary">{this.props.btn}</button>
          </div>
        </form>


      </div>
    )
  }
}

SearchQuery.defaultProps = {
  // categories: []
}

SearchQuery.propTypes = {
  // categories: PropTypes.array
}

window.renderSearchQuery = (el) => {
  const url = el.getAttribute('data-url')
  const btn = el.getAttribute('data-btn')
  const placeholder = el.getAttribute('data-placeholder')
  // const categories = JSON.parse(el.getAttribute('data-categories'))
  const query = el.getAttribute('data-query')

  ReactDOM.render(
    <SearchQuery url={url} btn={btn} query={query} placeholder={placeholder} />,
    el
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('search-query')

  if (el) {
    window.renderSearchQuery(el)
  }
})
